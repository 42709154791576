import React, {useState, useEffect, useContext} from 'react';
import styled from 'styled-components';
import Image from '../../../src/Components/Image';
import {Context} from '../../../src/AppContext';
import Tag from '../../../src/Components/ProductTag';
const config = require('../../../src/data.json');

export default function ProductItem(props) {
  const app = useContext(Context);
  const [freeShipping, setFreeShip] = useState(false);
  const [isPeriod, setIsPeriod] = useState(false);
  const {product, extraCss = '', onClick, link = '#'} = props;

  useEffect(() => {
    if (product) {
      try {
        const spec = app.actions.getProductFromSpec({
          productName: product.name,
        });
        setFreeShip(spec && spec.free_shipping);
        setIsPeriod(spec && spec.is_period);
      } catch (err) {}
    }
  }, [app.actions, product]);

  if (!product) {
    return null;
  }

  const handleOnClick = (e) => {
    if (onClick) {
      e.preventDefault();
      onClick(e);
    }
  };

  return (
    <Wrapper onClick={handleOnClick} css={extraCss} href={link}>
      <Image
        ratio={1}
        background
        src={product.image}
        style={{
          backgroundColor: '#e0e0e0',
          margin: 0,
          position: 'absolute',
          left: 0,
          top: 0,
        }}
      />
      <Content>
        <Text
          style={{
            textAlign: 'center',
            marginBottom: 6,
          }}>
          {product.name}
        </Text>

        {/* price from product model */}
        {product.original_price !== product.price && (
          // original price
          <Text
            color="#aaa"
            style={{
              textDecoration: 'line-through',
              textAlign: 'center',
            }}>
            NT: {product.original_price}
          </Text>
        )}
        {/* price */}
        <Text
          color={config.colors.main}
          style={{
            textAlign: 'center',
            fontWeight: '700',
          }}>
          NT: ${product.price}起
        </Text>
      </Content>

      <Tag
        product={product}
        freeShipping={freeShipping}
        onItem={true}
        isPeriod={isPeriod}
      />
    </Wrapper>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  flex: 1;
`;

const Wrapper = styled.a`
  display: inline-block;
  width: 100%;
  background-color: white;
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0px 3px 10px -3px #6665;
  }
  ${(props) => props.css}
`;

function Text(props) {
  const {color = '', weight = null, inline = false, style} = props;

  return (
    <div
      style={{
        fontSize: config.fontSize.sm,
        color: color || config.colors.text,
        ...(weight && {fontWeight: weight}),
        ...(inline && {display: 'inline'}),
        ...style,
      }}>
      {props.children}
    </div>
  );
}
