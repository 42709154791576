import React, {useContext, useCallback, useState, useEffect} from 'react';
import styled from 'styled-components';
import {Context} from '../../../src/AppContext';
import {Button, Row, Col} from 'antd';
import Image from '../../../src/Components/Image';
import StaticImage from '../../../src/Components/StaticImage';
import {navigate} from 'gatsby';
import ProductItem from '../../../src/Components/ProductItem';
import BlogItem from '../../../src/Components/BlogItem';
import Carousel from './BannerCarousel';
import PromoZoneTopSection from './PromoZoneTopSection';
import PromoZoneBottomSection from './PromoZoneBottomSection';
const appConfig = require('../../../src/data.json');
const slugify = require('uslug');

const intro_items = [
  {
    icon: '/images/home-icon-01.png',
    title: '商品多元',
    subtitle: '符合你送禮的需求',
  },
  {
    icon: '/images/home-icon-02.png',
    title: '配合插畫家',
    subtitle: '協助繪製您理想的作品',
  },
  {
    icon: '/images/home-icon-03.png',
    title: '急件服務',
    subtitle: '臨時的需求、急件安排',
  },
  {
    icon: '/images/home-icon-04.png',
    title: '專人服務',
    subtitle: '大宗訂單轉以專案處理',
  },
];

const social_icons = [
  {
    image: 'home-social-fb.png',
    link: appConfig.socialMedia.facebook.link,
  },
  {
    image: 'home-social-ig.png',
    link: appConfig.socialMedia.instagram.link,
  },
  {
    image: 'home-social-line.png',
    link: appConfig.socialMedia.line.link,
  },
];

export default function LandingPage(props) {
  const app = useContext(Context);
  const {promoItems} = app.state;
  const [products, setProducts] = useState([]);
  const [blogs, setBlogs] = useState([]);

  const getProducts = useCallback(async () => {
    try {
      let resp = await app.actions.getProducts();
      setProducts(
        resp.filter((p) => app.state.productNames.indexOf(p.name) > -1),
      );
    } catch (err) {
      console.warn(err);
    }
  }, [app.actions, app.state.productNames]);

  const getBlogs = useCallback(async () => {
    try {
      let resp = await app.actions.getBlogs({
        query: {
          $and: [
            {
              label: {$not: {$regex: 'FAQ'}},
            },
            {
              label: {$not: {$regex: 'draft'}},
            },
          ],
        },
        sorting: ['-created'],
      });
      setBlogs(resp);
    } catch (ex) {
      console.warn(ex);
    }
  }, [app.actions]);

  useEffect(() => {
    (async () => {
      app.actions.setLoading(true);
      await Promise.all([getProducts(), getBlogs()]);
      app.actions.setLoading(false);
    })();
  }, [app.actions, getProducts, getBlogs]);

  return (
    <Wrapper>
      <div className="banner-container">
        <Carousel imagesArray={promoItems.filter((x) => x.type === 'banner')} />
      </div>
      <div className="center-content">
        <div className={'sub-promote-banner-wrapper'}>
          <PromoZoneTopSection
            promoZones={promoItems.filter((x) => x.type === 'top_zone')}
          />
        </div>
        <div className="intro-items">
          {intro_items.map((item, idx) => (
            <ServiceItem index={idx} key={idx}>
              <img src={item.icon} height="60" width="60" alt="not found" />
              <div className="col">
                <p className="title" data-delighter>
                  {item.title}
                </p>
                <p className="subtitle" data-delighter>
                  {item.subtitle}
                </p>
              </div>
            </ServiceItem>
          ))}
        </div>
        <div className="row-section">
          <p className="title">熱門商品</p>
          <p className="subtitle">BSET SELLER</p>
          <Row
            gutter={[appConfig.productGridGutter, appConfig.productGridGutter]}
            style={{alignSelf: 'stretch'}}>
            {products
              .filter((p) => p.tag === 'HOT')
              .slice(0, 8)
              .map((p, idx) => {
                return (
                  <Col xs={12} md={8} lg={6} key={idx}>
                    <ProductItem
                      product={p}
                      onClick={() => {
                        navigate(`/product/${p.id}`);
                      }}
                    />
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>

      <div style={{background: '#ececec'}}>
        <PromoZoneBottomSection
          promoZones={promoItems.filter((x) => x.type === 'bottom_zone')}
        />
      </div>

      <div className="center-content">
        <div className="row-section">
          <p className="title">促銷商品</p>
          <p className="subtitle">PRODUCT PROMO</p>
          <Row
            gutter={[appConfig.productGridGutter, appConfig.productGridGutter]}
            style={{alignSelf: 'stretch'}}>
            {products
              .filter((p) => p.tag === 'NEW')
              .slice(0, 8)
              .map((p, idx) => {
                return (
                  <Col xs={12} md={8} lg={6} key={idx}>
                    <ProductItem
                      product={p}
                      onClick={() => {
                        navigate(`/product/${p.id}`);
                      }}
                    />
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>
      <IntroWrapper>
        <p data-delighter>
          <Image
            src="/images/logo-word.png"
            alt="logo"
            style={{
              width: '100%',
              height: 'auto',
              maxWidth: 250,
              marginBottom: 24,
            }}
            width="120"
            height="45"
          />
        </p>
        <p data-delighter>
          印印得致力於提倡生活美學，舉凡裝飾牆面的無框畫、床頭櫃小夜燈、餐桌杯墊..等，小小的巧思除了妝點生活情調，更能增加親友間的情感連結，送禮自用兩相宜。除此之外、展場活動、公關行銷宣傳贈品也是我們的一大主軸，歡迎有任何需求或想要開發商品皆可與我們聯絡。
        </p>
        <Button
          className="hover-reverse-btn"
          type="primary"
          onClick={() => navigate('/products')}
          style={{
            marginTop: 33,
            flexGrow: 0,
            border: 0,
          }}>
          全部商品
        </Button>
      </IntroWrapper>
      <div className="center-content">
        <div className="row-section">
          <p className="title">印學堂</p>
          <p className="subtitle">NEWS BLOG</p>
          <Row gutter={20} style={{alignSelf: 'stretch'}}>
            {blogs.slice(0, 3).map((record) => (
              <Col xs={24} md={8} key={record.id}>
                <BlogItem record={record} extraStyle={{}} type="portrait" />
              </Col>
            ))}
          </Row>
        </div>
        <div className="row-section">
          <p className="title">社群連結</p>
          <p className="subtitle">SOCAIL MEDIA</p>
          <div className="social-icons-wrapper">
            {social_icons.map((icon, idx) => (
              <a
                className={'social-icon'}
                key={idx}
                href={`${icon.link}`}
                target="_blank"
                rel="noreferrer">
                <StaticImage filename={`${icon.image}`} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fff;

  .banner-container {
    width: 100%;
    max-width: ${appConfig.maxContentWidth}px;
    margin: 0 auto;

    @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
      padding-top: 0px;
    }
  }

  .center-content {
    max-width: ${appConfig.maxContentWidth + 40}px;
    padding: 20px 20px;
    margin: 0 auto;

    @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
      padding: 0px 15px;
    }

    & > .sub-promote-banner-wrapper {
      overflow: hidden;
      @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
        padding-top: 15px;
      }
    }

    & .row-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 17px 0;

      & > .title {
        text-align: center;
        font-size: 22px;
        letter-spacing: 1px;
        color: ${appConfig.colors.third};
        margin: 10px 0 0 0;
      }

      & > .subtitle {
        text-align: center;
        font-size: 12px;
        color: #9b9b9c;
        border-bottom: 0.5px solid #bdbdbd;
        padding: 0 32px 10px 32px;
        margin-bottom: 45px;
      }

      & .social-icons-wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;

        & > .social-icon {
          flex: 1 1 30%;
        }

        & > .social-icon:nth-child(2) {
          margin: 0 20px;
        }

        @media screen and (max-width: 600px) {
          & > .social-icon {
            flex: 1 0 100%;
          }
          & > .social-icon:nth-child(2) {
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .intro-items {
    /* overflow: hidden; */
    display: flex;
    flex-wrap: wrap;
  }

  & .col {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    & > .title {
      letter-spacing: 2px;
      color: #5a5a5a;
      margin-bottom: 5px;
    }

    & > .title.delighter,
    & > .subtitle.delighter {
      transition: all 0.3s ease-out;
      transform: translateX(50%);
      opacity: 0;
    }

    & > .title.delighter.started,
    & > .subtitle.delighter.started {
      transform: none;
      opacity: 1;
    }

    & > .subtitle {
      color: #5a5a5a;
      font-size: 10px;
    }
  }
`;

const ServiceItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-basis: 25%;
  padding: 10px 0px;
  border-left: ${(props) => (props.index !== 0 ? '1px solid #bdbdbd' : 'none')};
  align-items: center;

  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    flex-basis: 50%;
    border-left: ${(props) =>
      props.index % 2 === 0 ? 'none' : '1px solid #bdbdbd'};
    border-bottom: ${(props) =>
      props.index < 2 ? '1px solid #bdbdbd' : 'none'};
  }

  & .col {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    flex-basis: 120px;

    & > .subtitle {
      color: #5a5a5a;
      font-size: 10px;
    }

    & p {
      margin-bottom: 0px;
      font-size: 14px;
    }

    & > .title {
      font-size: 18px;
      letter-spacing: 2px;
      color: ${appConfig.colors.third};
      margin-bottom: 5px;
    }
  }
  ${(props) => props.css}
`;

const IntroWrapper = styled.div`
  background-color: #f8f8f8;
  /* height: 230px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 42px;

  & > p.delighter {
    transition: all 0.3s ease-out;
    transform: translateY(50%);
    opacity: 0;
  }

  & > p.delighter.started {
    transform: none;
    opacity: 1;
  }

  & > p {
    max-width: 822px;
    width: 100%;
    text-align: center;
    color: #9a9a9a;
    letter-spacing: 2px;
    line-height: 35px;
  }

  & .hover-reverse-btn {
    background-color: ${appConfig.colors.main};
    color: white;
    &:hover {
      color: ${appConfig.colors.main};
      background-color: white;
    }
  }
`;
